@use "colors";
@use "variables";
@use "typography";
@use "mixins";

ul {
    margin: 0;
    padding: 0;
    list-style-type: none;
}

*:focus {
    outline: none;
}

body {
    margin: 0;
    padding: 0;
}

code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
        monospace;
}