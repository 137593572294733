@use  "../../../styles/partials/colors";
@use  "../../../styles/partials/mixins";

.hero {

    padding: 4rem 0;
    background-color: colors.$primary-black;
    background-image: url(../../../assets/images/back.png);
    background-repeat: no-repeat;
    background-size: cover;
    text-align: center;

    &__container {
        @include mixins.row;
        @include mixins.flex(center, center, 2rem) {
            flex-direction: column;
        }
    
        @include mixins.tablet {
            flex-direction: row;
            justify-content: space-around;
            align-items: center;
        }
    }


    &__logo {
        width: 50vw;

        @include mixins.tablet {
            width: 40vw;
        }

        @include mixins.desktop {
            width: 35vw;
        }
    }

}