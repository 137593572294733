@use '../../../styles/partials/colors';
@use '../../../styles/partials/mixins';

.nav {
    background-color: colors.$primary-black;
    position: fixed;
    width: 100%;
    padding: 1rem;
    box-sizing: border-box;
    z-index: 10;
    font-size: 1.2rem;

    @include mixins.tablet {
        padding: 1rem 3rem;
    }

    &__body {
        @include mixins.flex(space-between, center, false);
    }
    
    &__home-link {
        @include mixins.white-link;
    }

    &__list {
        display: none;
        @include mixins.tablet {
            @include mixins.flex(false, flex-end, 1rem 3rem);
        }
    }

    &__menu-link {
        cursor: pointer;
        color: colors.$primary-white;
        display: block;
        @include mixins.tablet {
            display: none;
        }
    }
}