@use "variables" as *;
@use "colors";

@mixin tablet() {
    @media (min-width: $tablet-breakpoint) {
        @content;
    }
}

@mixin desktop() {
    @media (min-width: $desktop-breakpoint) {
        @content;
    }
}

@mixin white-link() {
    text-decoration: none;
    color: colors.$primary-white;
}

@mixin red-link() {
    text-decoration: none;
    color: colors.$primary-red;
}

@mixin flex($justify, $align, $gap) {
    display: flex;

    @if $justify  !=false {
        justify-content: $justify;
    }

    @if $align  !=false {
        align-items: $align;
    }

    @if $gap  !=false {
        gap: $gap;
    }

    @content;
}

@mixin row() {

    padding: 3rem 1.5rem;

    @include tablet {
        padding-left: 2.25rem;
        padding-right: 2.25rem;
    }

    @include desktop {
        flex-direction: row;
        max-width: 80rem;
        margin-left: auto;
        margin-right: auto;
    }

    @content
}