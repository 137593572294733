@use  "../../styles/partials/mixins";
@use  "../../styles/partials/colors";
@use '../../styles/partials/globals';

.app {
    margin-top: 2rem;
}

.grecaptcha-badge { 
    visibility: hidden;
}

.home {
    &__container {
        @include mixins.row;
    }
    &__heading {
        border-bottom: 1px solid colors.$primary-red;
        width: 20%;
        padding-bottom: 0.75rem;
        margin-bottom: 2rem;
    }

    &__narrow {
        @include mixins.tablet {
            padding-left: 10%;
            padding-right: 10%;
            padding-top: 3rem;
            padding-bottom: 3rem;
        }

        @include mixins.desktop {
            padding-left: 15%;
            padding-right: 15%;
        }
    }
}