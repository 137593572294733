@use  "../../styles/partials/colors";
@use  "../../styles/partials/mixins";

.form {
    @include mixins.flex(space-between, false, 0.35rem) {
        flex-direction: column;
    }

    padding: 1rem;
    margin: -1rem;
    box-sizing: content-box;
    border-radius: 0.5rem;
    transition: all 0.25s ease-out;

    &--has-message {
        animation: blinker 2s linear forwards;
    }

    &__input, &__textarea, &__btn {
        padding: 0.35rem;
        font-size: 1.25rem;
        font-family: 'Lato', sans-serif;
        border-radius: 0.25rem;
        box-sizing: border-box;
        border: 2px transparent;

        &--has-error {
            border: 2px solid colors.$primary-red;
        }
    }

    &__textarea {
        resize: none;
    }

    &__error {
        color: colors.$primary-red;
        font-size: 0.75rem;
        margin: 0;
        font-weight: bold;
    }

    &__prefill {
        font-size: 0.75rem;
        background-color: colors.$secondary-grey;
        font-weight: bold;
        margin: 0;
        margin-bottom: -1rem;
        color: colors.$primary-black;
        padding: 0.5rem 0.5rem 1rem 0.5rem;
        border: 2px solid colors.$primary-black;
        border-top-left-radius: 0.25rem;
        border-top-right-radius: 0.25rem;
        line-height: 1rem;
    }

    &__submitted {
        background-color: colors.$secondary-green;
        border: 2px solid colors.$primary-green;
        color: colors.$primary-white;
        padding: 2rem;
        text-align: center;
        border-radius: 0.5rem;
        font-size: 1.25rem;
        font-weight: bold;
    }
}


@keyframes blinker {
    0% {
        background-color: colors.$primary-black;
    }
    10% {
        background-color: colors.$primary-green;
    }
    20% {
        background-color: colors.$primary-black;
    }
    30% {
        background-color: colors.$primary-green;
    }
    40% {
        background-color: colors.$primary-black;
    }
    50% {
        background-color: colors.$primary-green;
    }
    60% {
        background-color: colors.$primary-black;
    }
    70% {
        background-color: colors.$primary-green;
    }
    80% {
        background-color: colors.$primary-black;
    }
    90% {
        background-color: colors.$primary-green;
    }
    100% {
        background-color: colors.$primary-black;
    }
  }