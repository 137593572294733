@use "../../styles/partials/colors";
@use "../../styles/partials/mixins";

.card {
    flex-basis: 98%;
    box-sizing: content-box;
    text-align: center;
    border: 1px solid colors.$secondary-grey;
    border-radius: 0.5rem;
    overflow: hidden;
    position: relative;
    padding-bottom: 4rem;

    @include mixins.tablet {
        flex-basis: 30.5%;
    }

    @include mixins.desktop {
        flex-basis: 22%;
    }

    &__header {
        background-color: colors.$primary-red;
        color: colors.$primary-white;
        padding: 0.5rem 1rem;
        text-align: left;
    }

    &__heading {
        margin: 0;
    }

    &__image {
        width: 100%;
        height: 12rem;
        object-fit: cover;
    }

    &__placeholder {
        height: 12rem;
        background-color: colors.$secondary-white;
        animation: pulse 2s infinite;
    }

    &__equip {
        margin: 0.5rem 1rem 0;
    }

    &__desc {
        border-top: 1px solid colors.$secondary-grey;
        font-size: 0.75rem;
        line-height: 1rem;
        padding: 0.5rem 1rem 0 1rem;
    }

    &__footer {
        background-color: colors.$secondary-grey;
        padding: 0.5rem 0.5rem;
        position: absolute;
        bottom: 0;
        left: 0;
        right: 0;

        @include mixins.tablet {
            padding: 0.5rem 3rem;
        }

        @include mixins.flex(center, center, false);
    }

    &__btn {
        width: 75%;
    }
}

@keyframes pulse {
    0% {
        background-color: colors.$secondary-white;
    }

    50% {
        background-color: #777;
    }

    100% {
        background-color: colors.$secondary-white;
    }
}