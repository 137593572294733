@use  "../../../styles/partials/colors";

.loading {
    background: #000;
    width: 100%;
    height: 100%;
    z-index: 10;
    top: 0;
    left: 0;
    position: fixed;
    display: flex;
    justify-content: center;
    align-items: center;
    
    &__ring {
        display: inline-block;
        position: relative;
        width: 80px;
        height: 80px;
    }
    
    &__ring div {
        box-sizing: border-box;
        display: block;
        position: absolute;
        width: 64px;
        height: 64px;
        margin: 8px;
        border: 8px solid #fff;
        border-radius: 50%;
        animation: ring 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;
        border-color: colors.$primary-red transparent transparent transparent;
    }
    
    &__ring div:nth-child(1) {
        animation-delay: -0.45s;
    }
    
    &__ring div:nth-child(2) {
        animation-delay: -0.3s;
    }
    
    &__ring div:nth-child(3) {
        animation-delay: -0.15s;
    }
}



@keyframes ring {
    0% {
        transform: rotate(0deg);
    }

    100% {
        transform: rotate(360deg);
    }
}