@use  "../../../styles/partials/colors";
@use  "../../../styles/partials/mixins";

.footer {
    background-color: colors.$primary-black;
    color: colors.$primary-white;
    border-top: 0.5rem solid colors.$primary-red;
    border-bottom: 1.5rem solid colors.$primary-red;
    
    &__container {
        @include mixins.row;

        @include mixins.flex(space-between, false, 2rem) {
            flex-direction: column;
        }
    
        @include mixins.tablet() {
            flex-direction: row;
        }
    }

    &__third {
        border: 1px solid black;
        width: 100%;
        box-sizing: border-box;

        @include mixins.tablet() {
            border-right: 1px solid colors.$primary-grey;
            padding-right: 3rem;
            &--last {
                border-right: none;
                padding-right: 0;
            }
        }
    }

    &__hours {
        @include mixins.flex(flex-start, false, 1rem 2rem) {
            flex-direction: column;
        }

        @include mixins.desktop {
            flex-direction: row;
        }
    }

    &__hour {
        font-weight: 900;
    }

    &__link {
        color: colors.$primary-white;
        text-decoration: none;

        &:hover {
            opacity: 0.7;
        }
    }

    &__subheading {
        margin-bottom: 0.25rem;
    }

    &__heading {
        border-bottom: 1px solid colors.$primary-red;
        width: 40%;
        padding-bottom: 0.75rem;
        margin-bottom: 2rem;
        @include mixins.tablet {
            width: 60%;
        }
    }

    &__message {
        color: colors.$primary-white;
    }

    &__privacy {
        margin-top: 1rem;
        font-size: 0.75rem;
        text-align: center;

        a {
            color: colors.$secondary-grey;
            text-decoration: none;
        }
    }

}