@use  "../../styles/partials/colors";
@use  "../../styles/partials/mixins";

.button {
    color: colors.$primary-white;
    background-color: colors.$primary-red;
    padding: 0.35rem;
    font-size: 0.8rem;
    border-radius: 0.25rem;
    border: 2px solid colors.$primary-red;
    width: 100%;
    cursor: pointer;

    @include mixins.tablet {
        font-size: 1.25rem;
    }

    &--submit, &--add, &--edit  {
        background-color: colors.$secondary-grey;
        border: 2px solid colors.$secondary-grey;
        color: colors.$primary-grey;
        margin-top: 1rem;

        &:hover {
            background-color: colors.$secondary-grey !important;
            border-top: 2px solid colors.$secondary-grey !important;
            border-left: 2px solid colors.$secondary-grey !important;
            border-right: 2px solid colors.$accent-grey !important;
            border-bottom: 2px solid colors.$accent-grey !important;
        }

        &:active {
            background-color: colors.$secondary-grey !important;
            border-top: 2px solid colors.$accent-grey !important;
            border-left: 2px solid colors.$accent-grey !important;
            border-right: 2px solid colors.$secondary-grey !important;
            border-bottom: 2px solid colors.$secondary-grey !important;
        }
    }

    &--delete, &--edit {
        font-size: 0.8rem;
        padding: 0.15rem;
        margin-top: 0;
    }

    &:hover {
        background-color: colors.$secondary-red;
        border-top: 2px solid colors.$secondary-red;
        border-left: 2px solid colors.$secondary-red;
        border-right: 2px solid colors.$accent-red;
        border-bottom: 2px solid colors.$accent-red;
    }

    &:active {
        background-color: colors.$secondary-red;
        border-top: 2px solid colors.$accent-red;
        border-left: 2px solid colors.$accent-red;
        border-right: 2px solid colors.$secondary-red;
        border-bottom: 2px solid colors.$secondary-red;
    }
}