.no-match-page {
    background-color: #111;
    
    &__info {
        height: 100vh;
        display: flex;
        flex-direction: column;
        align-items: center;
        padding-top: 10rem;
    }

    &__error {
        font-size: 10rem;
        color: #777;
    }
    
    &__link {
        color: #e6e6e6;
        text-decoration: none;
    }
}