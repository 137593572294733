@use '../../../styles/partials/colors';
@use '../../../styles/partials/mixins';

.nav-side {
    position: fixed;
    top: 0;
    right: 0px;
    background-color: colors.$primary-grey;
    text-align: center;
    padding: 5rem 2rem 2rem 2rem;
    box-sizing: border-box;
    z-index: 5;
    display: block;
    transition: right .25s ease-in-out;
    height: 100%;
    width: 300px;


    &--slide-out {
        right: -300px;
    }

    &--slide-in {
        right: 0px;
    }

    @include mixins.tablet {
        display: none;
    }

    &__list {
        width: 100%;
    }

    &__list-item {
        display: block;
        padding: 0.75rem 2rem;

        &:hover {
            background-color: colors.$primary-black;
        }
    }

}