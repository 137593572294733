$primary-black: #000000;
$primary-white: #FFFFFF;
$primary-red: #ed1c24;
$primary-grey: #333;
$primary-green: #007500;
$secondary-green: #00A300;
$secondary-grey: #dcdcdc;
$secondary-red: #d61119;
$secondary-white: #999;
$accent-red: #970c11;
$accent-grey: #acacac;