@use "../../styles/partials/colors";
@use "../../styles/partials/mixins";

.cta {
    @include mixins.flex(space-between, false, 1rem);
    flex-direction: column;

    @include mixins.tablet {
        gap: 1.5rem;
    }

    &__ghost {
        transition: all .2s ease-in-out;
        box-shadow: 0 0 0 1px colors.$secondary-white;
        border-radius: 0.5rem;
        text-decoration: none;

        &:hover {
            box-shadow: 0 0 0 2px colors.$secondary-white;
        }
    }

    &__action {
        @include mixins.flex(center, center, 1rem);
        flex-direction: row;
        color: colors.$primary-white;
        padding: 0.5rem 5rem;
        transition: all .2s ease-in-out;

        @include mixins.tablet {
            padding: 0.25rem 4rem;
        }

        @include mixins.desktop {
            padding: 0.35rem 7rem;
        }

        &:hover {
            transform: scale(1.025);
        }
    }

    &__heading {
        margin: 0;
    }

    &__icon {
        display: block;
        color: colors.$primary-red;
        font-size: 1.5rem;
    }

}