@use  "../../styles/partials/colors";
@use  "../../styles/partials/mixins";

.banner {

    background-color: colors.$secondary-grey;

    &__container {
        @include mixins.flex(center, false, false);
        @include mixins.row() 
    }

    &__heading {

        font-size: 2.5rem;
        line-height: 3.5rem;
        width: 80%;
        text-align: center;

        @include mixins.tablet {
            font-size: 3.5rem;
            line-height: 4.75rem;
            width: 60%;
        }
        
    }
  
}
