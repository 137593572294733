@use  "../../styles/partials/colors";
@use  "../../styles/partials/mixins";

.card-list {
    @include mixins.flex(flex-start, stretch, 1rem 1rem) {
        flex-wrap: wrap;
    }
    @include mixins.tablet {
        gap: 1.5rem 1.5rem;
    }
    @include mixins.desktop {
        gap: 2rem 2rem;
    }
}